import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/GatsbyImage';
import SectionWrapper, { ContentWrapper } from './investment.style';
import dummyImg from 'common/assets/image/cryptoModern/pattern.png';

const InvestmentPortal = () => {
  const Data = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "image/cryptoModern/seamless2.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <SectionWrapper>
      <Container>
        <ContentWrapper>
          <div className="content">
            <Heading content="Seamless Integration, Effortless Execution" />
            <Text content="NadiAI's platform integrates effortlessly with your existing systems, enabling smooth deployment with no disruptions. Our intelligent global agents connect across your organization, providing access to internal data, automating workflows, and delivering actionable insights with precision. From analyzing data to driving business outcomes, our agents streamline your enterprise transformation. With just a few clicks, empower your teams to access the information they need and take your productivity to new heights." />
          </div>
          <div className="image">
            <GatsbyImage
              src={
                (Data.illustration !== null) | undefined
                  ? Data.illustration.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="Graph Image"
            />
          </div>
        </ContentWrapper>
      </Container>
      <Image className="patternImg" src={dummyImg} alt="pattern Image" />
    </SectionWrapper>
  );
};

export default InvestmentPortal;
