import React from 'react';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import Text from 'common/components/Text';
//import Button from 'common/components/Button';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/GatsbyImage';
import BannerWrapper, {
  BannerContent,
  //DiscountLabel,
  BannerImage,
  //ButtonGroup,
} from './banner.style';

const Banner = () => {
  const Data = useStaticQuery(graphql`
    query {
      illustration: file(
        relativePath: { eq: "image/cryptoModern/banner-bg.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <BannerWrapper id="home">
      <BannerImage>
          <Fade in delay={100}>
            {/*<GatsbyImage
              src={
                (Data.illustration !== null) | undefined
                  ? Data.illustration.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="Banner"
            />*/}


            <video src="/videos/hero-banner.mov" width="100%" loop preload='auto' autoPlay muted playsInline id="myvideo"  >
            <source type="video/mp4"></source>
       Your browser does not support the video tag.
       </video> 
       <script> document.getElementById('myvideo').play();  
       document.getElementById('myvideo').onloadedmetadata="this.muted = true"
       </script>
       
          </Fade>
        </BannerImage>
      <Container>
      
        <BannerContent>
          {/*<Fade up>
            <DiscountLabel>
              <Text className="discountAmount" content="25% Discount" />
              <Text
                className="discountText"
                content="on every first project "
              />
            </DiscountLabel>
          </Fade>*/}
          <Fade up delay={100}>
            <Heading
              as="h1"
              content="Simplify the Enterprise with AI Agents. One Platform, Infinite Solutions."
            />
          </Fade>
          <Fade up delay={200}>
            <Text
              content="Unlock the full potential of your enterprise with NadiAI’s AI-powered automation. Our intelligent agents enable seamless interactions across industries, turning data into actionable insights and automating complex workflows."
            />
          </Fade>
          {/*<Fade up delay={300}>
            <ButtonGroup>
              <Button className="primary" title="GET TOKEN" />
              <Button
                className="text"
                variant="textButton"
                title="WHITE PAPER"
              />
            </ButtonGroup>
          </Fade>*/}
        </BannerContent>
        
      </Container>
    </BannerWrapper>
  );
};

export default Banner;
