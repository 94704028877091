import React from 'react';
//import { useStaticQuery, graphql } from 'gatsby';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
//import GatsbyImage from 'common/components/GatsbyImage';
import SectionWrapper, {
  SectionHeader,
  //ImageWrapper,
} from './mapSection.style';

import country1 from 'common/assets/image/cryptoModern/email.png';
import country2 from 'common/assets/image/cryptoModern/mobile.png';
import country3 from 'common/assets/image/cryptoModern/location.png';
//import countryIcon from 'common/assets/image/cryptoModern/countryIcon.png';

const MapSection = () => {
  {/*const Data = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "image/cryptoModern/map.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1102
            placeholder: BLURRED
            formats: [AUTO, PNG, AVIF]
          )
        }
      }
    }
  `);*/}
  return (
    <SectionWrapper id="contactdetails">
      <Container width="1200px">
        <SectionHeader>
          <Heading content="Contact Us" />
          <Text content="Have questions? Reach out to us for expert assistance." />
          {/*<Text className="smallText" content="Licenced gambling countries" />*/}
          <div className="countries">
            <div className="countriesSingle">
              <Image src={country1} alt="country image" />
              <a href='mailto:support@nadiai.ai'><Text className="smallText" content="support@nadiai.ai" /></a>
            </div>
            <div className="countriesSingle">
              <Image src={country2} alt="country image" />
              <a href='tel:+510-683-0690'><Text className="smallText" content="+510-683-0690" /></a>
            </div>
          </div>
          <div className="countries">
            <div className="countriesSingle">
              <Image src={country3} alt="country image" />
              <Text className="smallText" content="6701 Koll Center Parkway, Suite 250,
Pleasanton, California, 94566" />
            </div>
          </div>

          {/*<div className="countries GMapwrapper">
            <div className="countriesSingle">
            <div className='GMap'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d101081.47689891265!2d-121.93780316104892!3d37.65399622147561!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fe832c9aa870b%3A0x6260ac3da483f984!2sPleasanton%2C%20CA%2094566%2C%20USA!5e0!3m2!1sen!2sin!4v1728305926346!5m2!1sen!2sin" width="800" height="350" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            </div>
            </div>*/}
        </SectionHeader>
       {/* <ImageWrapper>
          <GatsbyImage
            src={
              (Data.illustration !== null) | undefined
                ? Data.illustration.childImageSharp.gatsbyImageData
                : {}
            }
            alt="Privacy Illustration"
          />
          <div className="countryIcon">
            <div className="countryIconSingle">
              <Image
                className="countryIconImg"
                src={countryIcon}
                alt="Country Icon"
              />
            </div>
            <div className="countryIconSingle">
              <Image
                className="countryIconImg"
                src={countryIcon}
                alt="Country Icon"
              />
            </div>
            <div className="countryIconSingle">
              <Image
                className="countryIconImg"
                src={countryIcon}
                alt="Country Icon"
              />
            </div>
            <div className="countryIconSingle">
              <Image
                className="countryIconImg"
                src={countryIcon}
                alt="Country Icon"
              />
            </div>
            <div className="countryIconSingle">
              <Image
                className="countryIconImg"
                src={countryIcon}
                alt="Country Icon"
              />
            </div>
            <div className="countryIconSingle">
              <Image
                className="countryIconImg"
                src={countryIcon}
                alt="Country Icon"
              />
            </div>
            <div className="countryIconSingle">
              <Image
                className="countryIconImg"
                src={countryIcon}
                alt="Country Icon"
              />
            </div>
          </div>
        </ImageWrapper>*/}
      </Container>
    </SectionWrapper>
  );
};

export default MapSection;
